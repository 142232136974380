import React, {Component} from 'react';

export default class About extends Component {
    render() {
        let resumeData = this.props.resumeData;
        return (
            <section id="about">
                <div className="row">

                    <div className="three columns">

                        <img className="profile-pic" src="images/profilepic.jpg" alt=""/>

                    </div>

                    <div className="nine columns main-col">

                        <h2>About Me</h2>
                        <p>
                            {
                                resumeData.aboutme
                            }
                        </p>

                        <div className="row">

                            <div className="columns contact-details">

                                <h2>Contact Details</h2>
                                <p className="address">
                                    <span>{resumeData.name}</span>
                                    <br></br>
                                    <span>{resumeData.website}</span>
                                </p>

                            </div>

                        </div>
                        <h2>Relevant Coursework</h2>
                        <p>
                            [Algorithm Design & Analysis, Formal Languages & Automata Theory, Operating Systems, Mobile Computing, Computer Game Dev, Programming Paradigms, Computer Graphics, Database Systems, Social Networks, Open-Source Software Development, Data Communication & Computer Networks, Cloud Computing & Storage]
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}